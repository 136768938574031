<template>
    <div class='refundFailureList'>
        <div class="planlist">
            <el-row style="margin-bottom:1rem">
                <el-col :span="24" style="text-align:left">
                    <el-date-picker v-model="paginationInfo.condition.begin_date" align="right" type="date"
                        placeholder="选择提交时间" :picker-options="{
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                }">
                    </el-date-picker>
                    <el-select v-model="paginationInfo.condition.status" placeholder="请选择状态" @change="queryList"
                        filterable clearable>
                        <el-option label="未退款" value="0"></el-option>
                        <el-option label="已退款" value="1"></el-option>
                    </el-select>
                    <el-input style="width:200px;" v-model="paginationInfo.condition.out_trade_no"
                        placeholder="请输入商户订单号" clearable></el-input>
                    <el-button @click="queryList">查询</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="order_no" label="用户输入的订单号 " align='center'>
                </el-table-column>
                <el-table-column prop="out_trade_no" label="系统查询到的商户订单号 " align='center'>
                </el-table-column>
                <el-table-column label="提交日志" align='center'>
                    <template slot-scope="scope">
                        <div v-for="(item, i) in scope.row.fail_logs" :key="i" style="margin:.2rem 0">{{ item }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="状态" align='center' width="150">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0">未退款</span>
                        <span v-else-if="scope.row.status == 1">已退款</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationInfo.pageNo" :page-sizes="[10, 20, 50, 100]"
                :page-size="paginationInfo.pageSize" :total="paginationInfo.total"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            isHandleL: false,
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
                condition: {
                    begin_date: "",
                    // app_code: "languagemember",
                    // mobile: '',
                    // status: '',
                }
            },
            tableData: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        this.paginationInfo.condition.begin_date = this.$moment(new Date()).format('YYYY-MM-DD');
        this.getrefundFailLogs();
    },
    mounted() {
    },
    methods: {
        queryList() {
            this.paginationInfo.pageNo = 1;
            this.getrefundFailLogs()
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.paginationInfo.pageSize = val;
            this.paginationInfo.pageNo = 1;
            this.getrefundFailLogs();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.paginationInfo.pageNo = val;
            this.getrefundFailLogs();
        },
        getrefundFailLogs() {
            this.$service.OnlineOrderService
                .getrefundFailLogs(this.paginationInfo)
                .then(res => {
                    if (res.data.code === 0) {
                        let dataInfo = res.data.data;
                        this.paginationInfo.total = dataInfo.total || 1000;
                        this.tableData = dataInfo.trades;
                    }
                })
        },
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能,这个函数会触发
}
</script>

<style lang='less' scoped>
.refundFailureList {}
</style>